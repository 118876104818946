import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import {
  ExpandMore,
} from '@material-ui/icons';

import {
  Select,
  Toolbar,
  Typography, 
  ExpansionPanel,
  ExpansionPanelSummary, 
  ExpansionPanelDetails, 
  TextField,
} from '@material-ui/core';

// Demo Data
import { 
  place_list,
  sensor_device_master,
  thing_list,
} from '../DemoData';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

class DoorStateAnalyze extends React.Component {

  componentWillMount() {
  
    const placeList = this.loadPlaceList(this.props.deviceType);
    this.props.updatePlaceList(placeList);
  
    const things = this.locadThingList();
    this.props.updateThingList(things);
  
    //  console.log('DoorStateAnalyze componentWillMount (2) props:' + JSON.stringify(this.props));
  }

  
  render() {
    return (
      <div id={"state_analize_"+this.props.deviceType}>

        { this.renderToolBar() }

        { this.props.reportData && this.renderPlaceAnalyzeList() }

      </div>
    );
  }

  renderToolBar() {
    return (
      <Toolbar>
        <Typography variant="h5" color="inherit" style={{textAlign:"left", flexGrow:1}}>
          化粧室 施設状況履歴
        </Typography>

        <div style={{verticalAlign:"middle", textAlign:"right", flexGrow:2,}}>

          対象日:
          
          <DatePicker
            selected={this.props.targetDate}
            onChange={this.handleChange.bind(this)}
            dateFormat="yyyy/MM/dd"
            maxDate={new Date()}
            title="対象日"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Select
            ref="input_thing_name_state"
            id="input_thing_name_state"
            native
            value={this.props.thingName}
            onChange={this.handleChangeThingName.bind(this)}
            style={{width:'220px',textAlign:"left", }}
          >
            <option value="" />
            {this.props.things.map( (thing) => (
              <option key={thing.thing_name} value={thing.thing_name}>
                {thing.label}
              </option>
            ))}
          </Select>

          &nbsp;&nbsp;&nbsp;&nbsp;
          {/**
          <TextField
            label="Date"
            type="date"
            onChange={this.handleChange.bind(this)}
            defaultValue={this.props.targetDate}
            InputLabelProps={{
              shrink: true,
            }}
             
          />
          */}
        </div>
      </Toolbar>
    );
  }



  renderPlaceAnalyzeList() {

    const placeList = this.props.placeList;
    const thingDeviceMaster = this.props.deviceMaster;

    const placeDeviceMap=this.getPlaceDeviceMap(thingDeviceMaster, placeList);

    const state_data = this.buildStatePlaceData(
      this.props.reportData, this.props.targetDate, placeDeviceMap);
    
    return (
      <div>
        {Object.keys(placeDeviceMap).map(place_id => {
          return(
            this.renderPlaceAnalyze(
              //placeList[place_id],
              this.findPlace(place_id),
              state_data, 
              placeDeviceMap[place_id], 
              thingDeviceMaster)
          );
        })}
      </div>
    );

  }

  renderPlaceAnalyze(place, state_data, deviceIds, thingDeviceMaster) {

    const place_graph_data=this.buildGraphData(state_data['place'][place.place_id]);
    return (
      <ExpansionPanel key={"stateAnalyze_place_ExpansionPanel_" + place.place_id}>
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          {/*<Typography className={classes.heading}>Expansion Panel 1</Typography>*/}
          <div key={"stateAnalyze_place_sum_div_" + place.place_id}>
          <Typography variant="h6" color="inherit" style={{textAlign:"left", flexGrow:1}}>{place.label}</Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div key={"stateAnalyze_place_dtl_div_" + place.place_id}>
            <Tabs>
              <TabList>
                <Tab>利用時間率</Tab>
                <Tab>満室発生率</Tab>
                {deviceIds.map(device_id => {
                  const device = thingDeviceMaster[device_id];
                  return (
                    <Tab>{device.label}</Tab>
                    );
                })}
              </TabList>
              <TabPanel>
                <div key={"stateAnalyze_place_chart1_div_" + place.place_id}>
                  <Typography>{place.label} 利用時間率</Typography>
                  <BarChart
                    width={900}
                    height={220}
                    data={place_graph_data}
                    margin={{
                      top: 10, right: 15, left: 15, bottom: 5,
                    }}
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" />*/}
                    <XAxis dataKey="time" ticks={[
                      '00:00','01:00','02:00','03:00','04:00','05:00',
                      '06:00','07:00','08:00','09:00','10:00','11:00',
                      '12:00','13:00','14:00','15:00','16:00','17:00',
                      '18:00','19:00','20:00','21:00','22:00','23:00']} />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8"
                      domain={[0,100]} ticks={[0,25,50,75,100]}/>
                    <Tooltip />
                    <Legend />
                    <Bar name="Utilization rate(%)" yAxisId="left" dataKey="state" fill="#8884d8" />
                  </BarChart>          
                </div>
              </TabPanel>
              <TabPanel>
                <div key={"stateAnalyze_place_chart2_div_" + place.place_id}>
                  <Typography>{place.label} 満室発生率</Typography>
                  <BarChart
                    width={900}
                    height={220}
                    data={place_graph_data}
                    margin={{
                      top: 10, right: 15, left: 15, bottom: 5,
                    }}
                  >
                    {/* <CartesianGrid strokeDasharray="3 3" />*/}
                    <XAxis dataKey="time" ticks={[
                      '00:00','01:00','02:00','03:00','04:00','05:00',
                      '06:00','07:00','08:00','09:00','10:00','11:00',
                      '12:00','13:00','14:00','15:00','16:00','17:00',
                      '18:00','19:00','20:00','21:00','22:00','23:00']} />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8"
                      domain={[0,100]} ticks={[0,25,50,75,100]}/>
                    <Tooltip />
                    <Legend />
                    <Bar name="No empty rate(%)" yAxisId="left" dataKey="full" fill="#8884d8" />
                  </BarChart>          
                </div>
              </TabPanel>

              {deviceIds.map(device_id => {
                //const device = thingDeviceMaster[device_id];
                const device_data=state_data['door'][device_id];
                const device_graph_data=this.buildGraphData(device_data);
                return (
                  <TabPanel>
                    <div key={"stateAnalyze_device_div_" + device_id}>
                      {/*<Typography>{device.label}</Typography>*/}
                      <BarChart
                        width={900}
                        height={220}
                        data={device_graph_data}
                        margin={{
                          top: 10, right: 15, left: 15, bottom: 5,
                        }}
                      >
                      {/* <CartesianGrid strokeDasharray="3 3" />*/}
                      <XAxis dataKey="time"  ticks={[
                        '00:00','01:00','02:00','03:00','04:00','05:00',
                        '06:00','07:00','08:00','09:00','10:00','11:00',
                        '12:00','13:00','14:00','15:00','16:00','17:00',
                        '18:00','19:00','20:00','21:00','22:00','23:00']}
                        />
                      <YAxis yAxisId="left" orientation="left" stroke="#8884d8"
                        domain={[0,100]} ticks={[0,25,50,75,100]}/>
                      <Tooltip />
                      <Legend />
                      <Bar name="Utilization rate(%)" yAxisId="left" dataKey="state" fill="#8884d8" />
                      </BarChart>          
                    </div>
                  </TabPanel>
                );
              })}
            </Tabs>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>    
    );
  }




  handleChangeThingName(event) {
    const thingName = event.target.value;
    if (this.props.thingName !== thingName) {
      const deviceMaster = this.loadDeviceMaster(thingName, this.props.deviceType);
      this.props.changeThing(thingName, deviceMaster);
    }
    // ToDo: check targetDate
    this.updateDocument(thingName, this.props.targetDate);
  }

  handleChange(targetDate) {
    // this.setState({
    //   targetDate: date
    // });
    this.props.changeTargetDate(targetDate);

    if (this.props.thingName && this.props.thingName !== '') {
      this.updateDocument(this.props.thingName, targetDate);    
    }
  }

  handleReportDataErrors(res) {
    if (res.ok) {
      return res;
    }
  
    switch (res.status) {
      case 400: throw Error('INVALID_TOKEN');
      case 401: throw Error('UNAUTHORIZED');
      case 500: throw Error('INTERNAL_SERVER_ERROR');
      case 502: throw Error('BAD_GATEWAY');
      case 404: throw Error('NOT_FOUND');
      default:  throw Error('UNHANDLED_ERROR ' + res.status);
    } 
  };

  handleReportDataException(err) {
    this.props.errorReportData(err.message);
    console.log(err);
  }




  updateDocument(thingName, targetDate) {
    const date = new Date(targetDate.getFullYear(), targetDate.getMonth(),targetDate.getDate(), 0, 0);
    const targetDateStr = ('0000' + date.getUTCFullYear()).slice(-4) +
      ('0' + (date.getUTCMonth()+1)).slice(-2) +
      ('0' + date.getUTCDate()).slice(-2) + 
      ('0' + date.getUTCHours()).slice(-2);
    
    const url = 'https://k71b9o129c.execute-api.ap-northeast-1.amazonaws.com/v1/IoT000-sensor-data/'+ 
      thingName + '/' + targetDateStr;
    fetch(url,
    {
      mode: 'cors'
    })
      .then(this.handleReportDataErrors)
      .then(result=>result.json())
      .then(reportData=>this.props.receiveReportData(reportData))
      .catch(this.handleReportDataException.bind(this));
  }


  buildStatePlaceData(reportData, targetDate, placeDeviceMap) {
    //const localDate = this.state.targetDate;
    const state_data={
      'place': {},
      'door': {}
    };

    for (let place_id in placeDeviceMap) {
      state_data['place'][place_id] = Array(24*60);
      for(let i = 0; i<24*60; i++) {
        state_data['place'][place_id][i] = 0;
      }
      const deviceids = placeDeviceMap[place_id];
      for (let device_idx in deviceids) {
        const device_id = deviceids[device_idx];
        state_data['door'][device_id] = Array(24*60);
        for(let i=0; i<24*60; i++) {
          state_data['door'][device_id][i] = 0; // provisional
        }
      }
    }

    for (let hour = 0; hour < 24; hour++) {
      const target_datetime = new Date(targetDate.getFullYear(),targetDate.getMonth(),targetDate.getDate(),hour);
      //const utc_datetime=new Date(Date.UTC(target_datetime.getUTCFullYear(),target_datetime.getUTCMonth(),target_datetime.getUTCDate(),target_datetime.getUTCHours()));
      const date_str = target_datetime.getUTCFullYear().toString(10) +
        ('0'+(target_datetime.getUTCMonth()+1)).slice(-2) + 
        ('0'+target_datetime.getUTCDate()).slice(-2) +
        ('0'+target_datetime.getUTCHours()).slice(-2);
      
      if (reportData[date_str]) {
        for (let device_id in reportData[date_str]) {
          if (!state_data['door'][device_id]) {
            state_data['door'][device_id] = Array(24*60);
            for(let i = 0; i<24*60; i++) {
              state_data['door'][device_id][i] = 0; // provisional
            }
          }
          for (let minute = 0; minute<60; minute++) {
            let state = reportData[date_str][device_id][minute];
            if (state === 0 || state === 1) {
              state_data['door'][device_id][hour*60+minute] = state;
            } //else {
            //  state_data['door'][device_id][hour*60+minute] = undefined;
            //}
          }
        }
      } else {
        // ToDo:

      }
    }
    
    for (let place_id in placeDeviceMap) {
      const deviceids = placeDeviceMap[place_id];
      const device_count = deviceids.length;

      // for (let device_idx in deviceids) {
      //   const device_id = deviceids[device_idx];
      //   if ((typeof state_data['door'][device_id]) !== "undefined" ) {
      //     for(let i=0; i<24*60; i++) {
      //       const device_state = state_data['door'][device_id][i];
      //       if (device_state === 1) {
      //         state_data['place'][place_id][i] += 1;
      //       }
      //     }
      //   }
      // }
      for(let i=0; i<24*60; i++) {
        for (let device_idx = 0;  device_idx < device_count; device_idx++) {
          const device_id = deviceids[device_idx];
          if (state_data['door'][device_id]) {
            const device_state = state_data['door'][device_id][i];
            if (device_state >= 0) {
              state_data['place'][place_id][i] += device_state;
            }
          }
        }
        if (device_count > 0) {
          state_data['place'][place_id][i] = state_data['place'][place_id][i] / device_count;
        }
      }
    }

    return state_data;

  }


  buildGraphData(data) {
    const result = [];
    for (let hours=0;hours<24;hours++) {
      // for (let minute=0;minute<60;minute++) {
      //   const time_str = ('0'+hours).slice(-2)+':'+('0'+minute).slice(-2);
      //   result.push({time:time_str, state:data[hours*60+minute]});
      // }
      for (let minute_h=0;minute_h<6;minute_h++) { // 10 min avg.
        let sum_state = 0;
        let full_cnt = 0;
        for (let minute_l=0;minute_l<10;minute_l++) {
          const state = data[hours*60+minute_h*10+minute_l];
          sum_state += state;
          if (state >= 1) {
            full_cnt += 1;
          }
        }
        const avg_state =  Math.round(sum_state*10); // Math.round(state/10*100);
        const avg_full = full_cnt * 10;
        const time_str = ('0'+hours).slice(-2)+':'+minute_h+'0';
        result.push({time:time_str, state:avg_state, full:avg_full});
      }
    }
    return result;
  }



  getPlaceDeviceMap(thingDeviceMaster, placeList) {
    const placeDeviceMap={};
    //const placeIds = Object.keys(placeList);
    const placeIds = [];
    for (let placeIndex in placeList) {
      placeIds.push(placeList[placeIndex]['place_id']);
    }
    for (let deviceid in thingDeviceMaster) {
      let placeid = thingDeviceMaster[deviceid]['place_id'];
      if (!placeIds.find(id => {return (id === placeid);})) {
        placeid="000";
      }
      if (! placeDeviceMap[placeid]) {
        placeDeviceMap[placeid]=[]
      }
      placeDeviceMap[placeid].push(deviceid);
    }
    return placeDeviceMap;
  }


  findPlace(placeId) {
    for (let placeIndex in this.props.placeList) {
      if (this.props.placeList[placeIndex]['place_id'] === placeId)
        return this.props.placeList[placeIndex];
    }
    return null;//
  }






  

  loadPlaceList(deviceType) {
    // Demo Data
    return place_list[deviceType];
  }

  loadDeviceMaster(thingName, deviceType) {
    // Demo Data
    // ToDo: change to API
    if (!sensor_device_master[thingName] || !sensor_device_master[thingName][deviceType]) {
      return {};
    }

    return sensor_device_master[thingName][deviceType];
  }

  locadThingList() {
    return thing_list;
  }


}

export default DoorStateAnalyze;