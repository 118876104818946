import {
  SUBSCRIBE,
  UNSUBSCRIBE,
  CHANGE_THING,
  SHOW_DOOR_STATE_ALERT,
  CLOSE_DOOR_STATE_ALERT,
  SHOW_DOOR_STATE_DETAIL,
  CLOSE_DOOR_STATE_DETAIL,
  UPDATE_DEVICE_SHADOW_STATES,
  UPDATE_PLACE_LIST,
  UPDATE_THING_LIST,
  CHANGE_DOOR_STATE_ITEM_TAB,
} from '../constraints/actionTypes';

const initialState = {
  subscribing: false,
  thing: '',
  placeList: [],
  things: [],
  deviceMaster: {},
  states: {},


  doorStateAlertEnabled: false,
  doorStateAlertType: 'count',
  doorStateAlertTitle: '',
  doorStateAlertMessage: '',
  doorStateAlertState: null,

  doorStateDetailEnabled: false,
  doorStateDetailText: '',
  doorStateDetailReport: '',
  doorStateDetailTab: 'detail',

};

const deviceShadow = (state = initialState, action) => {
  console.log('deviceShadow reducer action:' + JSON.stringify(action) + ' state:' + JSON.stringify(state));

  switch (action.type) {
      case SUBSCRIBE:
        return Object.assign({},
          state,
          {
            subscribing: action.subscribing,
            states: action.states,
          }
        );
      case UNSUBSCRIBE:
        return Object.assign({},
          state,
          {
            subscribing: action.subscribing,
            states: action.states,
          }
        );
      case CHANGE_THING:
        return Object.assign({},
          state,
          {
            thing: action.thing,
            deviceMaster: action.deviceMaster,
          }
        );

      case SHOW_DOOR_STATE_ALERT:
        return Object.assign({},
          state,
          {
            doorStateAlertEnabled: true,
            doorStateAlertType: action.dialogType,
            doorStateAlertTitle: action.title,
            doorStateAlertMessage: action.message,
            doorStateAlertState: action.state,
          }
        );

      case CLOSE_DOOR_STATE_ALERT:
        return Object.assign({},
          state,
          {
            // doorStateAlert: {
            //   enabled: false,
            // }
            doorStateAlertEnabled: false,
          }
        );
      
      case SHOW_DOOR_STATE_DETAIL:
        return Object.assign({},
          state,
          {
            // doorStateDetail: {
            //   enabled: true,
            //   text: action.title,
            //   tab: action.tab,
            // }
            doorStateDetailEnabled: true,
            doorStateDetailText: action.text,
            doorStateDetailReport: action.report,
            doorStateDetailTab: action.tab,
          }
        );

        case CHANGE_DOOR_STATE_ITEM_TAB:
        return Object.assign({},
          state,
          {
            doorStateDetailTab: action.tab
          }
        );
    
      case CLOSE_DOOR_STATE_DETAIL:
        return Object.assign({},
          state,
          {
            // doorStateDetail: {
            //   enabled: false,
            // }
            doorStateDetailEnabled: false,
          }
        );

        
      case UPDATE_DEVICE_SHADOW_STATES:
        return Object.assign({},
          state,
          {
            states: action.states
          }
        );
      
      case UPDATE_PLACE_LIST:
        return Object.assign({},
          state,
          {
            //deviceShadow: {
              placeList: action.placeList
            //}
          }
        );

      case UPDATE_THING_LIST:
        return Object.assign({},
          state,
          {
            things: action.things
          }
        );

      default:
          console.log('deviceShadow reducer is initialized.');
          return state;
    }
};


export default deviceShadow;