import { connect } from 'react-redux';

import DoorCountAnalyze from '../components/DoorCountAnalyze';

import {
    changeTargetDate,
    receiveReportData,
    requestReportData,
    errorReportData,

    changeThing,
    updatePlaceList,
    updateThingList,

} from '../actions/countAnalyze';
  
// maps to Redux store -> component props
const mapStateToProps = (state) => (
    {
      targetDate: state.countAnalyze.targetDate,
      reportData: state.countAnalyze.reportData,
      isLoading: state.countAnalyze.isLoading,
      errorMessage: state.countAnalyze.errorMessage,

      thingName: state.countAnalyze.thing,
      deviceMaster: state.countAnalyze.deviceMaster,
      placeList: state.countAnalyze.placeList,
      things: state.countAnalyze.things,
    }
);

const mapDispatchToProps = (dispatch, state) => (
    {
      changeTargetDate: (targetDate) => { dispatch(changeTargetDate(targetDate)) },
      receiveReportData: (reportData) => { dispatch(receiveReportData(reportData)) },
      requestReportData: () => { dispatch(requestReportData()) },
      errorReportData:  (errorMessage) => { dispatch(errorReportData(errorMessage)) },

      changeThing: (thingName, deviceMaster) => { dispatch(changeThing(thingName, deviceMaster)) },
      updatePlaceList: (placeList) => { dispatch(updatePlaceList(placeList)) },
      updateThingList: (things) => { dispatch(updateThingList(things)) },
    }
);

export default connect(mapStateToProps,mapDispatchToProps)(DoorCountAnalyze);
