import { connect } from 'react-redux';

import DoorStateAnalyze from '../components/DoorStateAnalyze';

import {
    changeTargetDate,
    receiveReportData,
    requestReportData,
    errorReportData,

    changeThing,
    updatePlaceList,
    updateThingList,

  } from '../actions/stateAnalyze';
  
// maps to Redux store -> component props
const mapStateToProps = (state) => (
    {
      targetDate: state.stateAnalyze.targetDate,
      reportData: state.stateAnalyze.reportData,
      isLoading: state.stateAnalyze.isLoading,
      errorMessage: state.stateAnalyze.errorMessage,

      thingName: state.stateAnalyze.thing,
      deviceMaster: state.stateAnalyze.deviceMaster,
      placeList: state.stateAnalyze.placeList,
      things: state.stateAnalyze.things,
    }
);

const mapDispatchToProps = (dispatch, state) => (
    {
      changeTargetDate: (targetDate) => { dispatch(changeTargetDate(targetDate)) },
      receiveReportData: (reportData) => { dispatch(receiveReportData(reportData)) },
      requestReportData: () => { dispatch(requestReportData()) },
      errorReportData:  (errorMessage) => { dispatch(errorReportData(errorMessage)) },

      changeThing: (thingName, deviceMaster) => { dispatch(changeThing(thingName, deviceMaster)) },
      updatePlaceList: (placeList) => { dispatch(updatePlaceList(placeList)) },
      updateThingList: (things) => { dispatch(updateThingList(things)) },
    }
);

export default connect(mapStateToProps,mapDispatchToProps)(DoorStateAnalyze);
