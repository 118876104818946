
import {
  STATE_CHANGE_TARGET_DATE,
  STATE_REQUEST_REPORT_DATA,
  STATE_RECEIVE_REPORT_DATA,
  STATE_ERROR_REPORT_DATA,

  STATE_CHANGE_THING,
  STATE_UPDATE_PLACE_LIST,
  STATE_UPDATE_THING_LIST,



  } from '../constraints/actionTypes';

const initialState = {
    targetDate: new Date(),
    reportData: null,
    isLoading: false,
    errorMessage: null,

    thing: '',
    deviceMaster: {},
    placeList: [],
    things: [],
};

const stateAnalyze = (state = initialState, action) => {
    console.log('stateAnalyze reducer action:' + JSON.stringify(action) + ' state:' + JSON.stringify(state));
    switch (action.type) {

        case STATE_CHANGE_TARGET_DATE:
        return Object.assign({},
          state,
          {
            targetDate: action.targetDate,
          }
        );
        case STATE_REQUEST_REPORT_DATA:
        return Object.assign({},
          state,
          {
            reportData: null,
            isLoading: true,
            errorMessage: null,
          }
        );

        case STATE_RECEIVE_REPORT_DATA:
        return Object.assign({},
          state,
          {
            reportData: action.reportData,
            isLoading: false,
            errorMessage: null,
          }
        );

        case STATE_ERROR_REPORT_DATA:
        return Object.assign({},
          state,
          {
            reportData: null,
            isLoading: false,
            errorMessage: action.errorMessage,
          }
        );

        case STATE_CHANGE_THING:
        return Object.assign({},
          state,
          {
            thing: action.thing,
            deviceMaster: action.deviceMaster,
          }
        );
        case STATE_UPDATE_PLACE_LIST:
        return Object.assign({},
          state,
          {
            placeList: action.placeList
          }
        );

      case STATE_UPDATE_THING_LIST:
        return Object.assign({},
          state,
          {
            things: action.things
          }
        );
        default:
        console.log('stateAnalyze reducer is initialized.');
        return state;
  }
};

export default stateAnalyze;