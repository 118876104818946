import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import {
  ExpandMore,
} from '@material-ui/icons';

import {
  Select,
  Toolbar,
  Typography, 
  ExpansionPanel,
  ExpansionPanelSummary, 
  ExpansionPanelDetails, 
} from '@material-ui/core';

// Demo Data
import { 
  place_list,
  sensor_device_master,
  thing_list,
} from '../DemoData';


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";


class DoorCountAnalyze extends React.Component {

  componentWillMount() {

      const placeList = this.loadPlaceList(this.props.deviceType);
      this.props.updatePlaceList(placeList);
  
      const things = this.locadThingList();
      this.props.updateThingList(things);
  
      //console.log('DoorCountAnalyze componentWillMount (2) props:' + JSON.stringify(this.props));
  }
      
  render() {
    return (
      <div id={"count_analize_"+this.props.deviceType}>

        { this.renderToolBar() }

        { this.props.reportData  && this.renderPlaceAnalyzeList() }
      
      </div>
    );
  }

  renderToolBar() {
    return (
      <Toolbar>
        <Typography variant="h5" color="inherit" style={{textAlign:"left", flexGrow:1}}>
          化粧室 ご利用分析
        </Typography>

        <div style={{verticalAlign:"middle", textAlign:"right", flexGrow:2,}}>

          対象日:

          <DatePicker
            selected={this.props.targetDate}
            onChange={this.handleChange.bind(this)}
            dateFormat="yyyy/MM/dd"
            maxDate={new Date()}
            title="対象日"
          />

          &nbsp;&nbsp;&nbsp;&nbsp;

          <Select
            ref="input_thing_name_count"
            id="input_thing_name_count"
            native
            value={this.props.thingName}
            onChange={this.handleChangeThingName.bind(this)}
            style={{width:'220px',textAlign:"left", }}
          >
            <option value="" />
            {this.props.things.map( (thing) => (
              <option key={thing.thing_name} value={thing.thing_name}>
                {thing.label}
              </option>
            ))}
          </Select>

          &nbsp;&nbsp;&nbsp;&nbsp;

        </div>
      </Toolbar>
    );
  }

  renderPlaceAnalyzeList() {

    const placeDeviceMap=this.getPlaceDeviceMap(
      this.props.deviceMaster,
      this.props.placeList);
    const count_report_data = this.buildCountReportData(this.props.reportData, this.props.targetDate, placeDeviceMap);

    return (
      <div>
        {Object.keys(placeDeviceMap).map(place_id => {
          return(
            this.renderPlaceAnalyze(
              this.findPlace(place_id),
              count_report_data,
              placeDeviceMap[place_id],
              this.props.deviceMaster)
          );
        })}
      </div>
    );

  }



  renderPlaceAnalyze(place, data, deviceIds, thingDeviceMaster) {

    const place_data=data['place'][place.place_id];

    return (
      <ExpansionPanel key={"countAnalyze_place_ExpansionPanel_" + place.place_id}>
        <ExpansionPanelSummary expandIcon={<ExpandMore/>}>
          <div key={"countAnalyze_place_sum_div_" + place.place_id}>
          <Typography variant="h6" color="inherit" style={{textAlign:"left", flexGrow:1}}>{place.label}</Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div key={"countAnalyze_place_dtl_div_" + place.place_id}>
            <Tabs>
              <TabList>
                <Tab>合計</Tab>
                {deviceIds.map(device_id => {
                  const device = thingDeviceMaster[device_id];
                  return (
                    <Tab>{device.label}</Tab>
                    );
                })}
              </TabList>
              <TabPanel>
                <div key={"countAnalyze_place_chart1_div_" + place.place_id}>
                  <Typography style={{ whiteSpace: 'pre-line' }}>{
                    '総利用回数:' + place_data['total_count'] + "回 \n" +
                    (place_data['total_count'] > 0 
                      ? ' 総平均時間:' + Math.round(place_data['total_time']/place_data['total_count']) + ' 秒'
                      : ''
                    ) + " / " + 
                    ' 最大時間: ' + place_data['total_max_time'] + ' 秒'
                  }</Typography>
                  {/* <ResponsiveContainer height='40%' width='80%'> */}
                  <BarChart
                    width={700}
                    height={200}
                    data={place_data['data']}
                    margin={{ top: 10, right: 20, left: 20, bottom: 5, }}
                  >
                    {/*<CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey="hour" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                    <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                    <Tooltip />
                    <Legend />
                    <Bar name="Count" yAxisId="left" dataKey="count" fill="#8884d8"/>
                    <Bar name="Avg.(sec)" yAxisId="right" dataKey="avg" fill="#82ca9d"/>
                    <Bar name="Max.(sec)" yAxisId="right" dataKey="max" fill="#c2ca9d"/>
                  </BarChart> 
                  {/* </ResponsiveContainer>      */}   
                </div>
              </TabPanel>
            
              {deviceIds.map(device_id => {
                const device_data=data['door'][device_id]
                return (
                  <TabPanel>
                  <div key={"countAnalyze_device_div_" + device_id}>
                    {/*<Typography>{device.label}</Typography>*/}
                    <Typography style={{ whiteSpace: 'pre-line' }}>{
                    '利用回数合計: ' + device_data['total_count'] + "回 \n" +
                    (device_data['total_count'] > 0 
                      ? ' 平均時間:' + Math.round(device_data['total_time']/device_data['total_count']) + ' 秒 / '
                      : ''
                    ) + 
                    ' 最大時間: ' + device_data['total_max_time'] + ' 秒'
                    }</Typography>
                    <BarChart
                      width={700}
                      height={200}
                      data={device_data['data']}
                      margin={{ top: 10, right: 10, left: 10, bottom: 5, }}
                    >
                      {/*<CartesianGrid strokeDasharray="3 3" />*/}
                      <XAxis dataKey="hour" />
                      <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                      <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                      <Tooltip />
                      <Legend />
                      <Bar name="Count" yAxisId="left" dataKey="count" fill="#8884d8"  />
                      <Bar name="Avg.(sec)" yAxisId="right" dataKey="avg" fill="#82ca9d"   />
                      <Bar name="Max.(sec)" yAxisId="right" dataKey="max" fill="#c2ca9d"   />
                    </BarChart>          
                  </div>
                  </TabPanel>
                );
              })}
            </Tabs>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>    
    );
  }



  handleChangeThingName(event) {
    const thingName = event.target.value;
    if (this.props.thingName !== thingName) {
      const deviceMaster = this.loadDeviceMaster(thingName, this.props.deviceType);
      this.props.changeThing(thingName, deviceMaster);
    }

    // ToDo: check targetDate
    this.updateDocument(thingName, this.props.targetDate);
  }


  handleChange(targetDate) {
    this.props.changeTargetDate(targetDate);

    if (this.props.thingName && this.props.thingName !== '') {
      this.updateDocument(this.props.thingName, targetDate);
    }
  }


  findPlace(placeId) {
    for (let placeIndex in this.props.placeList) {
      if (this.props.placeList[placeIndex]['place_id'] === placeId)
        return this.props.placeList[placeIndex];
    }
    return null;//
  }

  updateDocument(thingName, targetDate) {
    const date = new Date(targetDate.getFullYear(), targetDate.getMonth(),targetDate.getDate(), 0, 0);
    const targetDateStr = ('0000' + date.getUTCFullYear()).slice(-4) +
      ('0' + (date.getUTCMonth()+1)).slice(-2) +
      ('0' + date.getUTCDate()).slice(-2) + 
      ('0' + date.getUTCHours()).slice(-2);
    
    const url = 'https://k71b9o129c.execute-api.ap-northeast-1.amazonaws.com/v1/IoT000-sensor-report/'+thingName+'/'+targetDateStr;
    console.log('fetching to '+ url);
    this.props.requestReportData();
    fetch(url,{ mode: 'cors'})
      .then(this.handleReportDataErrors.bind(this))
      .then(result=>result.json())
      .then(reportData=>this.props.receiveReportData(reportData))
      .catch(this.handleReportDataException.bind(this));

    // const url = 'https://k71b9o129c.execute-api.ap-northeast-1.amazonaws.com/v1/IoT000-sensor-report/'+thingName+'/'+targetDateStr;
    // this.asyncReportDataRequest(url);
  }

  asyncReportDataRequest = async (url) => {
    this.props.requestReportData();
    try {
      await fetch(url,{ mode: 'cors'})
        .then(this.handleReportDataErrors.bind(this))
        .then(result=>result.json())
        .then(reportData=>this.props.receiveReportData(reportData))
        .catch(this.handleReportDataException.bind(this));
    } catch (e) {
      this.props.errorReportData(e.message);
      console.log(e);
    }
  }

  handleReportDataErrors(res) {
    if (res.ok) {
      return res;
    }
  
    switch (res.status) {
      case 400: throw Error('INVALID_TOKEN');
      case 401: throw Error('UNAUTHORIZED');
      case 500: throw Error('INTERNAL_SERVER_ERROR');
      case 502: throw Error('BAD_GATEWAY');
      case 404: throw Error('NOT_FOUND');
      default:  throw Error('UNHANDLED_ERROR ' + res.status);
    } 
  };

  handleReportDataException(err) {
    this.props.errorReportData(err.message);
    console.log(err);
  }

  loadPlaceList(deviceType) {
    // Demo Data
    return place_list[deviceType];
  }

  loadDeviceMaster(thingName, deviceType) {
    // Demo Data
    // ToDo: change to API
    if (!sensor_device_master[thingName] || !sensor_device_master[thingName][deviceType]) {
      return {};
    }

    return sensor_device_master[thingName][deviceType];
  }

  locadThingList() {
    return thing_list;
  }



  getPlaceDeviceMap(thingDeviceMaster, placeList) {
    const placeDeviceMap={};
    const placeIds = [];

    for (let placeIndex in placeList) {
      placeIds.push(placeList[placeIndex]['place_id']);
    }

    for (let deviceid in thingDeviceMaster) {
      let placeid = thingDeviceMaster[deviceid]['place_id'];
      if (!placeIds.find(id => {return (id === placeid);})) {
        placeid="000";
      }
      if (! placeDeviceMap[placeid]) {
        placeDeviceMap[placeid]=[]
      }
      placeDeviceMap[placeid].push(deviceid);
    }
    return placeDeviceMap;
  }

  buildCountReportData(reportData, targetDate, placeDeviceMap) {
    const count_data={
      'place': {},
      'door': {}
    };

    for (let place_id in placeDeviceMap) {
      if (! count_data['place'][place_id]) {
        count_data['place'][place_id] = {};
        count_data['place'][place_id]['data'] = Array(24);
        for (let h = 0; h < 24; h++) {
          count_data['place'][place_id]['data'][h] = { hour:h, count:0, avg:0, max:0 };
        }
        count_data['place'][place_id]['total_count'] = 0;
        count_data['place'][place_id]['total_time'] = 0;
        count_data['place'][place_id]['total_max_time'] = 0;
      }
      for (let device_id_idx in placeDeviceMap[place_id]) {
        const device_id = placeDeviceMap[place_id][device_id_idx];
        count_data['door'][device_id] = {};
        count_data['door'][device_id]['data'] = Array(24);
        for (let h = 0; h < 24; h++) {
          count_data['door'][device_id]['data'][h] = { hour:h, count:0, avg:0, max:0 };
        }
        count_data['door'][device_id]['total_count'] = 0;
        count_data['door'][device_id]['total_time'] = 0;
        count_data['door'][device_id]['total_max_time'] = 0;
      }
    }

    for (let hour = 0; hour < 24; hour++) {
      const target_datetime = new Date(targetDate.getFullYear(),targetDate.getMonth(),targetDate.getDate(),hour);
      const date_str = ('0000' + target_datetime.getUTCFullYear()).slice(-4) +
      ('0' + (target_datetime.getUTCMonth()+1)).slice(-2) +
      ('0' + target_datetime.getUTCDate()).slice(-2) + 
      ('0' + target_datetime.getUTCHours()).slice(-2);
      
      if (this.props.reportData[date_str]) {
        for (let place_id in placeDeviceMap) {
          count_data['place'][place_id]['data'][hour] = {
            hour:hour,
            count:reportData[date_str]['place'][place_id]['count'],
            avg:reportData[date_str]['place'][place_id]['avg_time'],
            max:reportData[date_str]['place'][place_id]['max_time']
          };
          count_data['place'][place_id]['total_count'] += reportData[date_str]['place'][place_id]['count'];
          count_data['place'][place_id]['total_time'] += reportData[date_str]['place'][place_id]['total_time'];
          if ( reportData[date_str]['place'][place_id]['max_time'] > count_data['place'][place_id]['total_max_time']) {
            count_data['place'][place_id]['total_max_time'] = reportData[date_str]['place'][place_id]['max_time'];
          }
        }
        for (let device_id in reportData[date_str]['door']) {
          count_data['door'][device_id]['data'][hour] = {
            hour:hour,
            count:reportData[date_str]['door'][device_id]['count'],
            avg:reportData[date_str]['door'][device_id]['avg_time'],
            max:reportData[date_str]['door'][device_id]['max_time']
          };
          count_data['door'][device_id]['total_count'] += reportData[date_str]['door'][device_id]['count'];
          count_data['door'][device_id]['total_time'] += reportData[date_str]['door'][device_id]['total_time'];
          if (reportData[date_str]['door'][device_id]['max_time'] > count_data['door'][device_id]['total_max_time']) {
            count_data['door'][device_id]['total_max_time'] = reportData[date_str]['door'][device_id]['max_time'];
          }
        }
      } else {
        //
      }
    }
    return count_data;
  }

}

export default DoorCountAnalyze;