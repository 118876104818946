import { combineReducers } from 'redux'

import main from './main'
import deviceShadow from './deviceShadow'
import countAnalyze from './countAnalyze'
import stateAnalyze from './stateAnalyze'


const root = combineReducers({
   main,
   deviceShadow,
   countAnalyze,
   stateAnalyze,
 })

//export const appReducer = deviceShadow;

export default root;