
// Demo Data
//export const things = [
export const thing_list = [
    {
      thing_name: 'DeviceGateway01',
      label: 'Virtual Device Gateway 01',
    },
    {
      thing_name: 'TestRasPi3_001',
      label: 'Raspberry Pi 001',
    },
    {
      thing_name: 'TestRasPi3_003',
      label: 'Raspberry Pi 003',
    },
  ];
  
  export const place_list = {
    door:[
      {
        place_id: "001",
        label:"男性化粧室",
      },
      {
        place_id: "002",
        label:"女性化粧室",
      },
      {
        place_id: "003",
        label:"男女共用",
      },
      {
        place_id: "000",
        label:"不明",
      },
    ]
  };
  
  export const sensor_device_master = {
    "DeviceGateway01": {
      door: {
        "00000001": {
          device_id: "00000001",
        //  "000001": {
        //    device_id: "000001",
            label: "男性用１",
          display_order: 0,
          place_id: "001",
        },
        "00000002": {
          device_id: "00000002",
        //"000002": {
        //  device_id: "000002",
          label: "男性用２",
          display_order: 1,
          place_id: "001",
        },
        "00000003": {
          device_id: "00000003",
          label: "男性用３",
          display_order: 2,
          place_id: "001",
        },
        "00000004": {
          device_id: "00000004",
        //"000002": {
        //  device_id: "000002",
          label: "女性用１",
          display_order: 0,
          place_id: "002",
        },
        "00000005": {
          device_id: "00000005",
          label: "女性用２",
          display_order: 1,
          place_id: "002",
        },
        "00000006": {
          device_id: "00000006",
          label: "女性用３",
          display_order: 2,
          place_id: "002",
        },
        "00000007": {
          device_id: "00000007",
        //"000002": {
        //  device_id: "000002",
          label: "多目的トイレ",
          display_order: 0,
          place_id: "003",
        },
      }
    },
    "TestRasPi3_001": {
      door: {
      }
    },
    "TestRasPi3_003": {
      door: {
      }
    },
  };
  
  