import { connect } from 'react-redux';

import DoorStateList from '../components/DoorStateList';

import {
    unsubscribe,
    subscribe,
    updateDeviceShadowStates,
  
    changeThing,
    showDoorStateAlert,
    closeDoorStateAlert,
    showDoorStateDetail,
    closeDoorStateDetail,
    changeDoorStateItemTab,
    updatePlaceList,
    updateThingList,
  
} from '../actions/deviceShadow';
  
// maps to Redux store -> component props
const mapStateToProps = (state) => (  //{
//function mapStateToProps (state)  {
    //console.log('DoorStateListContainer mapStateToProps state:' + JSON.stringify(state));

    //return {
    {
      thingName: state.deviceShadow.thing,
      deviceMaster: state.deviceShadow.deviceMaster,
      placeList: state.deviceShadow.placeList,
      things: state.deviceShadow.things,
      states: state.deviceShadow.states,
      subscribing: state.deviceShadow.subscribing,
  
      // alertDialogOpen: state.doorStateAlert.enabled,
      // alertDialogType: state.doorStateAlert.type,
      // alertDialogTitle: state.doorStateAlert.title,
      // alertDialogMessage: state.doorStateAlert.message,
      // alertDialogState: state.doorStateAlert.state,
      
      // stateItemDrawerOpen: state.doorStateDetail.enabled,
      // stateItemDrawerText: state.doorStateDetail.text,
      // stateItemTabsSelect: state.doorStateDetail.tab,
  
      alertDialogOpen: state.deviceShadow.doorStateAlertEnabled,
      alertDialogType: state.deviceShadow.doorStateAlertType,
      alertDialogTitle: state.deviceShadow.doorStateAlertTitle,
      alertDialogMessage: state.deviceShadow.doorStateAlertMessage,
      alertDialogState: state.deviceShadow.doorStateAlertState,
  
      stateItemDrawerOpen: state.deviceShadow.doorStateDetailEnabled,
      stateItemDrawerText: state.deviceShadow.doorStateDetailText,
      stateItemDrawerReport: state.deviceShadow.doorStateDetailReport,
      stateItemTabsSelect: state.deviceShadow.doorStateDetailTab,

    }
//}
);

  // maps to action creator & dispach to store -> component props
const mapDispatchToProps = (dispatch, state) => (
//function mapDispatchToProps (dispatch, state)  {
    //console.log('DoorStateListContainer mapDispatchToProps state:' + JSON.stringify(state));

    //return {
    {
      changeThing: (thingName, deviceMaster) => { dispatch(changeThing(thingName, deviceMaster)) },
      updatePlaceList: (placeList) => { dispatch(updatePlaceList(placeList)) },
      updateThingList: (things) => { dispatch(updateThingList(things)) },
      subscribe: () => { dispatch(subscribe())},
      unsubscribe: () => { dispatch(unsubscribe())},
      showDoorStateAlert: (doorStateAlert) => {dispatch(showDoorStateAlert(doorStateAlert))},
      closeDoorStateAlert: () => {dispatch(closeDoorStateAlert())},
      showDoorStateDetail: (doorStateDetail) => {dispatch(showDoorStateDetail(doorStateDetail))},
      closeDoorStateDetail: () => {dispatch(closeDoorStateDetail())},
      updateDeviceShadowStates: (states) => {dispatch(updateDeviceShadowStates(states))},
      changeDoorStateItemTab: (tab) =>{dispatch(changeDoorStateItemTab(tab))},
    }
//};
);

//export default connect(mapStateToProps,mapDispatchToProps)(DoorStateList);
//const DoorStateListContainer = connect(mapStateToProps,mapDispatchToProps)(DoorStateList);
//export default DoorStateListContainer;
export default connect(mapStateToProps,mapDispatchToProps)(DoorStateList);
