// ReactJS
import React, { Component } from 'react';

import './App.css';

// Amplify
import { Authenticator } from 'aws-amplify-react';
import { withAuthenticator } from 'aws-amplify-react';
import Amplify from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';

// Material-UI
import {
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';

// import { 
//   //AppBar,
//   // Toolbar,
//   // Button,
//   // IconButton,
//   // Typography,
//   // Menu,
//   // MenuItem,
// } from '@material-ui/core';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

// Sub Components
import DoorStateList from '../containers/DoorStateListContainer';
import DoorCountAnalyze from '../containers/DoorCountAnalyzeContainer';
import DoorStateAnalyze from '../containers/DoorStateAnalyzeContainer';

// Icons
// import {
//   Autorenew,
//   Menu as MenuIcon, 
// } from '@material-ui/icons';

const aws_config = {
  Auth: {
    identityPoolId: 'ap-northeast-1:f9b8ce69-ddda-403d-9b5a-1ce88df42d58',
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_LmJ7dL3yY',
    userPoolWebClientId: '7dtcj29p021b7ej6507ucnn8a7',
  },
  PubSub: {
    aws_pubsub_region: 'ap-northeast-1',
    aws_pubsub_endpoint: 'wss://a26co4me436ueo-ats.iot.ap-northeast-1.amazonaws.com/mqtt'
  }
};

Amplify.configure({
  Auth: aws_config['Auth']
});
// provisional
Amplify.addPluggable(new AWSIoTProvider(aws_config['PubSub']));


const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
/*
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff4400',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
*/
});


class App extends Component {

  render() {
    //console.log('App render  props:' + JSON.stringify(this.props));
    //console.log('App render  props.menuTab:' + JSON.stringify(this.props.menuTab));

    return (
      <MuiThemeProvider theme={theme}>
      <div className="App">
        {/*<Authenticator theme={auth_theme} />*/}
        <Authenticator />
        {/* 
        <AppBar
          position="static"
          color="default"
          >
        </AppBar>
        */}
        <Tabs>
          <TabList>
            <Tab>ご利用状況</Tab>
            <Tab>ご利用分析</Tab>
            <Tab>施設状況履歴</Tab>
          </TabList>
          <TabPanel>
            <DoorStateList
              deviceType="door"
            />
          </TabPanel>
          <TabPanel>
            <DoorCountAnalyze
              deviceType='door'
            />
          </TabPanel>
          <TabPanel>
            <DoorStateAnalyze
              deviceType='door'
            />
          </TabPanel>
        </Tabs>
        
        {/*
        <Menu
          id="Main Menu"
          anchorEl={this.state.menuAnchorEl}
          open={Boolean(this.state.menuAnchorEl)}
          onClose={this.handleCloseMenu.bind(this)}
        >
          <MenuItem onClick={this.handleMenuItemStateClick.bind(this)}>状況</MenuItem>
          <MenuItem onClick={this.handleMenuItemAnalizeClick.bind(this)}>分析</MenuItem>
        </Menu>
        */}
      </div>
      </MuiThemeProvider>
    )
  }


  componentDidMount() {
    // Setup Master Data

    console.log('App component is mounted.');

    console.log('App componentDidMount props:' + JSON.stringify(this.props));
    console.log('App componentDidMount  props.menuTab:' + JSON.stringify(this.props.menuTab));

  }

}

export default withAuthenticator(App, {includeGreeting: true});
//export default App;

