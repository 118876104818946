export const CHANGE_MENU_TAB = 'CHANGE_MENU_TAB';


export const SUBSCRIBE = 'SUBSCRIBE';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const CHANGE_THING = 'CHANGE_THING';
export const SHOW_DOOR_STATE_ALERT = 'SHOW_DOOR_STATE_ALERT';
export const CLOSE_DOOR_STATE_ALERT = 'CLOSE_DOOR_STATE_ALERT';
export const SHOW_DOOR_STATE_DETAIL = 'SHOW_DOOR_STATE_DETAIL';
export const CLOSE_DOOR_STATE_DETAIL = 'CLOSE_DOOR_STATE_DETAIL';
export const UPDATE_DEVICE_SHADOW_STATES = 'UPDATE_DEVICE_SHADOW_STATES';
export const UPDATE_PLACE_LIST = 'UPDATE_PLACE_LIST';
export const UPDATE_THING_LIST = 'UPDATE_THING_LIST';
export const CHANGE_DOOR_STATE_ITEM_TAB = 'CHANGE_DOOR_STATE_ITEM_TAB';



//export const CHANGE_THING = '';

export const COUNT_CHANGE_TARGET_DATE = 'CCOUNT_HANGE_TARGET_DATE';
export const COUNT_REQUEST_REPORT_DATA = 'COUNT_REQUEST_REPORT_DATA';
export const COUNT_RECEIVE_REPORT_DATA = 'COUNT_RECEIVE_REPORT_DATA';
export const COUNT_ERROR_REPORT_DATA = 'COUNT_ERROR_REPORT_DATA';
export const COUNT_CHANGE_THING = 'COUNT_CHANGE_THING';
export const COUNT_UPDATE_PLACE_LIST = 'COUNT_UPDATE_PLACE_LIST';
export const COUNT_UPDATE_THING_LIST = 'COUNT_UPDATE_THING_LIST';


export const STATE_CHANGE_TARGET_DATE = 'STATE_CHANGE_TARGET_DATE';
export const STATE_REQUEST_REPORT_DATA = 'STATE_REQUEST_REPORT_DATA';
export const STATE_RECEIVE_REPORT_DATA = 'STATE_RECEIVE_REPORT_DATA';
export const STATE_ERROR_REPORT_DATA = 'STATE_ERROR_REPORT_DATA';
export const STATE_CHANGE_THING = 'STATE_CHANGE_THING';
export const STATE_UPDATE_PLACE_LIST = 'STATE_UPDATE_PLACE_LIST';
export const STATE_UPDATE_THING_LIST = 'STATE_UPDATE_THING_LIST';
