// ReactJS
//import React, { Component } from 'react';
import { connect } from 'react-redux'
//import { withAuthenticator } from 'aws-amplify-react';

import App from '../components/App';

import { changeMenuTab } from '../actions/main';

// import { withAuthenticator } from 'aws-amplify-react';
// //import Amplify, { PubSub } from 'aws-amplify';
// import Amplify from 'aws-amplify';
// import { AWSIoTProvider } from '@aws-amplify/pubsub';



// const aws_config = {
//   Auth: {
//     identityPoolId: 'ap-northeast-1:f9b8ce69-ddda-403d-9b5a-1ce88df42d58',
//     region: 'ap-northeast-1',
//     userPoolId: 'ap-northeast-1_LmJ7dL3yY',
//     userPoolWebClientId: '7dtcj29p021b7ej6507ucnn8a7',
//   },
//   PubSub: {
//     aws_pubsub_region: 'ap-northeast-1',
//     aws_pubsub_endpoint: 'wss://a26co4me436ueo-ats.iot.ap-northeast-1.amazonaws.com/mqtt'
//   }
// };

// Amplify.configure({
//   Auth: aws_config['Auth']
// });
// // provisional
// Amplify.addPluggable(new AWSIoTProvider(aws_config['PubSub']));



const mapStateToProps = (state) => ( //{
  //return { 
  {
    //thingName: state.thing,
    //states: state.deviceShadowStates,
    //subscribing: state.subscribing,
    menuTab: state.main.menuTab,
  }
//}
);

const mapDispatchToProps = (dispatch, state) => ( //{
  //return {
  {
    //changeThing: (value) => { dispatch(changeThing(value)) }
    changeMenuTab: (menuTab) => { dispatch(changeMenuTab(menuTab))},
  }
//};
);

//const mergeProps = (mapStateToProps, mapDispatchToProps, )


//export default connect(mapStateToProps,mapDispatchToProps)(withAuthenticator(App, {includeGreeting: true}));
//export default connect(mapStateToProps,mapDispatchToProps)(withAuthenticator(App, {includeGreeting: true}));


export default connect(mapStateToProps,mapDispatchToProps)(App);

//const AppContainer = connect(mapStateToProps,mapDispatchToProps)(App);
//export default AppContainer;