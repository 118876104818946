import {
  CHANGE_MENU_TAB,
} from '../constraints/actionTypes';

const initialState = {
  menuTab: 'initial',
};

const main = (state = initialState, action) => {
  console.log('main reducer action:' + JSON.stringify(action) + ' state:' + JSON.stringify(state));

  switch (action.type) {
      case CHANGE_MENU_TAB:
        return Object.assign({},
          state,
          {
            menuTab: action.menuTab,
          }
        );
      default:
        console.log('main reducer is initialized.');
        return state;
  }
};

export default main;