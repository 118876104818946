import {
    STATE_CHANGE_TARGET_DATE,
    STATE_REQUEST_REPORT_DATA,
    STATE_RECEIVE_REPORT_DATA,
    STATE_ERROR_REPORT_DATA,

    STATE_CHANGE_THING,
    STATE_UPDATE_PLACE_LIST,
    STATE_UPDATE_THING_LIST,
  
} from '../constraints/actionTypes';

export const updatePlaceList = (placeList) => {
    return {
      type: STATE_UPDATE_PLACE_LIST,
      placeList: placeList,
    };
  };
  
  export const updateThingList = (things) => {
    return {
      type: STATE_UPDATE_THING_LIST,
      things: things,
    };
  };
  
export const changeThing = (thingName, deviceMaster) => {
    return {
      type: STATE_CHANGE_THING,
      thing: thingName,
      deviceMaster: deviceMaster,
    };
};
      
export const changeTargetDate = (targetDate) => {
    return {
        type: STATE_CHANGE_TARGET_DATE,
        targetDate: targetDate,
    };
};
    
export const requestReportData = () => {
    return {
        type: STATE_REQUEST_REPORT_DATA,
        isLoading: true,
        errorMessage: null,
    };
};

export const receiveReportData = (reportData) => {
    return {
        type: STATE_RECEIVE_REPORT_DATA,
        reportData: reportData,
    };
};

export const errorReportData = (errorMessage) => {
    return {
        type: STATE_ERROR_REPORT_DATA,
        reportData: null,
        isLoading: false,
        errorMessage: errorMessage,
    };
};