
import React from 'react';
import {
  Autorenew,
//  Menu as MenuIcon, 
} from '@material-ui/icons';


import { 
  List, 
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';

import {
  Button,
  Dialog,
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  Drawer,
  Tabs,
  Tab,
  Toolbar,
  Select,
  Typography,
} from '@material-ui/core';


import {
  Person, 
  PersonOutline,
  ErrorOutline,
  //Warning, 
  Announcement,
  Mood,
  NotificationsActive,
  Wc,
} from '@material-ui/icons';
import { isNull, isUndefined } from 'util';

// Demo Data
import { 
  place_list,
  sensor_device_master,
  thing_list,
} from '../DemoData';

import { IotWebPubSubClient } from './IotWebPubSubClient';

const door_alert_timer_sec = 180;
const door_alert_count = 5;
const door_state_timeout_sec = 3600;

const hd_btn_styles = {
  width: '120px',
  margin: '20px 5px',
};

const list_styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  }
});

//export class DoorStateList extends React.Component {
export default class DoorStateList extends React.Component {
  
  componentWillMount() {

//    console.log('DoorStateList componentWillMount (1) props:' + JSON.stringify(this.props));
//    console.log('DoorStateList componentWillMount (1) this.state:' + JSON.stringify(this.state));

    const { deviceType } = this.props;

    const placeList = this.loadPlaceList(deviceType);
    this.props.updatePlaceList(placeList);

    const things = this.locadThingList();
    this.props.updateThingList(things);

    console.log('DoorStateList componentWillMount (2) props:' + JSON.stringify(this.props));
  }

  componentDidMount() {
    // provisional
    this.client = new IotWebPubSubClient({
      handleGetDocumentEvent: this.handleGetDocumentEvent.bind(this),
      handleUpdateDocumentEvent: this.handleUpdateDocumentEvent.bind(this),
      handleCloseEvent: this.handleCloseEvent.bind(this),
    });

    console.log('DoorStateList componentDidMount');
    this.interval = setInterval(() => this.refresh(), 1000);
  }

  // componentWillReceiveProps(nextProps) {
  //   console.log('DoorStateList componentWillReceiveProps props:' + JSON.stringify(nextProps));
  // }

  componentWillUnmount() {
    clearInterval(this.interval);
    
    if (this.props.subscribing) {
      this.client.stopSubscribing();
      this.props.unsubscribe();
    }
  }


  render() {
    //console.log('DoorStateList render');

    //console.log('DoorStateList render props:' + JSON.stringify(this.props));
    //console.log('DoorStateList render this.state:' + JSON.stringify(this.state));

    //console.log('DoorStateList render this.props.placeList:' + JSON.stringify(this.props.placeList));

    //const { classes, /*states,*/ deviceType /*, placeList */ } = this.props;
    const classes = list_styles;
    //const deviceType = 'door';
    const { deviceType } = this.props;
    //const { placeList } = this.props;
    
    return (
      <div id={"stateListDiv_" + deviceType}>
        {this.renderToolBar()}
        {
          this.props.placeList.map( (place) => {
            const { list, inuse, total, unknown } = this.getStateList(this.props.states, deviceType, place.place_id);
            if (total > 0) {
              return (this.renderDoorStateList(deviceType, place, list, inuse, total, unknown, classes));
            }
            return '';
          })
        }
        {this.renderDoorStateListItemDetail()}
        {this.renderDoorStateAlertDialog()}
      </div>
    );
  }

  // Rendering

  renderToolBar() {
    //console.log('thingName:' + this.props.thingName);
    //console.log('things:' + JSON.stringify(this.props.things));

    return (
      <Toolbar>
        {/*
        <IconButton color="inherit" style={{marginLeft: -12,marginRight: 20,}}
          aria-label="Menu"
          onClick={this.handleMenuClick.bind(this)}
        ><MenuIcon/></IconButton>
        */}
        <Typography variant="h5" color="inherit" style={{textAlign:"left", flexGrow:1}}>
          化粧室 ご利用状況
        </Typography>
        <div style={{verticalAlign:"middle", textAlign:"right", flexGrow:2,}}>
        {/*
        <TextField
          ref="input_thing_name"
          id="input_thing_name"
          select
          / * label="Thing" * /
          value={this.state.thingName}
          onChange={this.handleChangeThingName}
          / * SelectProps={{MenuProps:{className: classes.menu}}} * /
          / * helperText="Select 'Thing Name' of AWS IoT Things" * /
          margin="normal"
          style={{width:'220px',textAlign:"left", }}
        >
          {things.map(thing => (
          <option key={thing.thing_name} value={thing.thing_name}>
            {thing.label}
          </option>
        ))}
        </TextField>
        */}
        <Select
          ref="input_thing_name"
          id="input_thing_name"
          native
          /*label="Thing"*/
          //value={this.state.thingName}
          value={this.props.thingName}
          //onChange={this.handleChangeThingName}
          onChange={this.handleChangeThingName.bind(this)}
          /*SelectProps={{MenuProps:{className: classes.menu}}}*/
          /*helperText="Select 'Thing Name' of AWS IoT Things"*/
          /*margin="normal"*/
          style={{width:'220px',textAlign:"left", }}
        >
          <option value="" />
          {this.props.things.map( (thing) => (
            <option key={thing.thing_name} value={thing.thing_name}>
              {thing.label}
            </option>
          ))}

        </Select>
        { //this.state.subscribing
          this.props.subscribing
          ? <Button style={hd_btn_styles} variant="contained" size="small" color="primary" onClick={this.handleUnsubscribe.bind(this)} ref="btn_unsubscribe" margin="normal">Unsubscribe</Button>
          : <Button style={hd_btn_styles} variant="contained" size="small" color="primary" onClick={this.handleSubscribe.bind(this)} ref="btn_subscribe"margin="normal"> Subscribe </Button>
        }
        {/*
        <Button style={hd_btn_styles} variant="contained" size="small" color="primary" disabled={!this.state.subscribing} onClick={this.handleGet} ref="btn_get">
          Refresh
        </Button>
        */}
        <Button variant="contained" size="small" color="primary" onClick={this.handleGet.bind(this)} disabled={!this.props.subscribing} ref="btn_reconnect"><Autorenew/></Button>
        </div>
      </Toolbar>
    );
  }

  renderDoorStateList(deviceType, place, list, inuse, total, unknown, classes) {
    return (
      //{/*<div id={"stateListDiv_" + deviceType + "_" + place.place_id}>*/}

      <List
        key={"stateList_" + deviceType + "_" + place.place_id}
        className={classes.root}
        subheader={
          this.renderDoorStateListHeader(total,inuse,unknown,place)
        }
        style={{overflow:'auto'}}
      >
        {list.map(state => {
          return (this.renderDoorStateListItem(state));
        })}
      </List>
      //{/*</div>*/}
    );

  }

  renderDoorStateListItem(state) {
    const close_diff_sec = state.lastCloseTime ? 
        (Math.floor(Date.now() / 1000) - state.lastCloseTime) : 0;

    const timer_base_time = state.lastTimerClearTime ? state.lastTimerClearTime : state.lastCloseTime;
    const timer_diff_sec = timer_base_time ? 
        (Math.floor(Date.now() / 1000) - timer_base_time) : 0;

    const state_timeout = (Math.floor(Date.now()/1000)) - state.timestamp > door_state_timeout_sec;

    return (
      <ListItem
        key={state.device_type + ':' + state.device_id} 
        onClick={this.handleStateListItemClick.bind(this, state)}
      >
        <ListItemIcon>
          {
            (state_timeout) ? 
              <ErrorOutline fontSize="large" color="disabled"/> :
                (state.state === "close") ? 
                  <Person fontSize="large" color="action"/> : 
                  <PersonOutline fontSize="large" color="disabled"/> 
          }
        </ListItemIcon>
        <ListItemText
          primary={
            state.label
              ? state.label + ' (' + state.device_id +')'
              : '名称未設定 (' + state.device_id +')'
          }
          secondary={
            (state_timeout 
              ? /*'最終更新: ' +  (new Date(state.timestamp*1000).toLocaleDateString()) + ' ' +
                (new Date(state.timestamp*1000).toLocaleTimeString())*/
                (new Date(state.timestamp*1000).toLocaleDateString()) + ' ' +
                (new Date(state.timestamp*1000).toLocaleTimeString()) + 
                ' 以後のデータがありません。通信状態を確認して下さい'
              /*:''
            ) +
            ' 清掃後使用: ' + state.count + '回 ' + 
            (!state_timeout && state.state === "close" && close_diff_sec > 0 
              ? ('   使用時間: ' + Math.floor(close_diff_sec/60) + '分' + (close_diff_sec%60) + '秒' ) 
              : ''
            ) */
              : (state.state === "close"
                ? 'ご利用中です ' + 
                  (close_diff_sec > 0 
                    ? '(ご利用時間: ' + 
                      (Math.floor(close_diff_sec/60) > 0 
                        ? Math.floor(close_diff_sec/60) + '分' 
                        : ''
                      ) + (close_diff_sec%60) + '秒)' 
                    : ''
                  )
                : '現在、空いています ' + (state.count > 0 ? '(清掃後ご利用回数: ' + state.count + ')' : '')
              )
            )
          }
        />
        <ListItemSecondaryAction>
          {
            (!state_timeout && state.state === "close" && timer_diff_sec >= door_alert_timer_sec) 
              ? <Announcement fontSize="large" color="secondary" onClick={this.handleTimerAlertIcon.bind(this, state)}/> 
              : (
                ( state.count > door_alert_count )
                  ? <NotificationsActive fontSize="large" color="error" onClick={this.handleCountAlertIcon.bind(this, state)}/>
                  : <Mood fontSize="large" color="disabled"/>
              )
          }
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  renderDoorStateListHeader(total,inuse,unknown,place) {
    const unuse = total-inuse-unknown;
    const header_msg =
      (total<=0)
      ? ''
      : (unknown > 0)
        ? (unuse > 0)
          ? place.label + ' (全' + total + '室): 現在 ' + unknown + '室の状況が不明です  (空き'+ unuse + '室)'
          : place.label + ' (全' + total + '室): 現在 ' + unknown + '室の状況が不明です '
        : (unuse > 0)
          ? place.label + ' (全' + total + '室): 現在 ' + unuse + '室の空きがあります '
          : place.label + ' (全' + total + '室): 現在空きがありません '
      ;
    let wc_color = "action";
    if (place.place_id === "001") {
      wc_color="primary";
    } else if (place.place_id === "002") {
      wc_color="secondary";
    }

    return (
      <ListSubheader component="div" fontSize="large" color="inherit">
        <div style={{fontSize:'large', textAlign: 'left', verticalAlign: 'middle'}}>
          <Wc color={wc_color} style={{margin: '0px 10px',}}/>
          {header_msg}
        </div>
      </ListSubheader>
    );
  }

  renderDoorStateListItemDetail() {
    return (
      <Drawer
        open={this.props.stateItemDrawerOpen}
        onClose={this.handleCloseStateListItem.bind(this)}
        anchor="bottom"
      >
        <Tabs
          value={this.props.stateItemTabsSelect}
          onChange={this.handleStateItemTabsSelect.bind(this)}>
          <Tab value="detail" label="Detail" ></Tab>
          <Tab value="report" label="Report" ></Tab>
        </Tabs>
        { this.props.stateItemTabsSelect === 'detail' &&
            <div>
            {
              this.props.stateItemDrawerText.split("\n").map((value, index) => {
                return (<span key={'StateItemDrawerText_Span_' + index}>{value}<br/></span>);
              })
            }
            </div>
        }
      </Drawer>
    );
  }

  renderDoorStateAlertDialog() {
    return (
      <Dialog
        open={this.props.alertDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.alertDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {this.props.alertDialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { (this.props.alertDialogType === 'count' || this.props.alertDialogType === 'timer') ? 
            <Button onClick={this.handleClearAlert.bind(this)} color="primary">Clear</Button> : ''
          }
          <Button onClick={this.handleCloseAlertDialog.bind(this)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    );
  }




  handleChangeThingName(event) {
    const thingName = event.target.value;
    if (this.props.thingName !== thingName) {
      if (this.props.subscribing) {
        this.client.stopSubscribing();
        this.props.unsubscribe();
      }

      const deviceMaster = this.loadDeviceMaster(thingName);

      this.props.changeThing(thingName, deviceMaster);
      this.client.startSubscribing(thingName);
      this.props.subscribe();
    }
  }

  handleSubscribe(){
    if (!this.props.subscribing) {
      this.client.startSubscribing(this.props.thingName);
      this.props.subscribe();
    }
  }

  handleUnsubscribe(){
    if (this.props.subscribing) {
      this.client.stopSubscribing();
      this.props.unsubscribe();
    }
  }

  handleGet(){
    if (this.props.subscribing) {
      this.client.publishGet();
    }
  }

  handleClearCount(device_type, device_id) {
   if (this.props.subscribing) {
      this.client.publishClearCount(device_type, device_id);
    }
  }

  handleClearTimer(device_type, device_id) {
    if (this.props.subscribing) {
       this.client.publishClearTimer(device_type, device_id);
     }
   }


  handleStateListItemClick(state) {
    console.log('handleStateListItemClick');

    const status_msg = 
      '名称:' + (state.label ? state.label : '名称未設定') + "\n" +
      ' 状態: ' +  state.state + "\n" +
      ' 最終更新: ' +  (new Date(state.timestamp*1000).toLocaleDateString()) + ' ' + 
        (new Date(state.timestamp*1000).toLocaleTimeString()) + "\n" +
      ' 清掃後ご利用回数: ' + state.count + "回\n" +
      ' 総カウント: ' + state.totalCount + "回\n" +
      ' センサーID:' + state.device_id + "\n\n";

    const report_msg = "";


    this.props.showDoorStateDetail({
      text: status_msg,
      report: report_msg,
      tab: 'detail',
    });
  }

  handleStateItemTabsSelect(event, value) {
    console.log('handleStateItemTabsSelect:' + value);
    this.props.changeDoorStateItemTab(value);
  }

  handleCloseStateListItem() {
    console.log('handleCloseStateListItem');
    this.props.closeDoorStateDetail({
    });
  }

  handleCountAlertIcon(state) {
   this.props.showDoorStateAlert({
      dialogType: 'count',
      title: state.device_id + 'の清掃を行ってください',
      message: '清掃後の利用回数が' + door_alert_count + '回を超えました(現在'+state.count+'回)。清掃を行った後、Clearボタンを押してください。',
      state: state
    });
  }

  handleTimerAlertIcon(state) {
    let message;
    if (state.lastTimerClearTime) {
      const timer_base_time = state.lastTimerClearTime ? state.lastTimerClearTime : state.lastCloseTime;
      const timer_diff_sec = timer_base_time ? 
        (Math.floor(Date.now() / 1000) - timer_base_time) : 0;
      
      const timer_diff_time = (Math.floor(timer_diff_sec/60) > 0 ? Math.floor(timer_diff_sec/60) + '分' : '') +
        (timer_diff_sec%60) + '秒';

      message = '前回の確認より ' + timer_diff_time + ' 経過しています、状況を確認してください。'

    } else {
      const close_diff_sec = state.lastCloseTime ? 
        (Math.floor(Date.now() / 1000) - state.lastCloseTime) : 0;

      const close_diff_time = (Math.floor(close_diff_sec/60) > 0 ? Math.floor(close_diff_sec/60) + '分' : '') +
        (close_diff_sec%60) + '秒';
    
      const alert_timer_time = Math.floor(door_alert_timer_sec/60) + '分' + (door_alert_timer_sec%60) + '秒';
      
      message = 'ご利用時間が ' + alert_timer_time + ' を超えています(現在 '+close_diff_time+')、状況を確認してください。'
    }

    this.props.showDoorStateAlert({
      dialogType: 'timer',
      title: 
        (state.label
          ? state.label + ' (' + state.device_id +')'
          : '名称未設定 (' + state.device_id +')'
        ) + 'の状況を確認して下さい',
      message: message,
      state: state
    });

  }

  handleClearAlert() {
    if (isUndefined(this.props.alertDialogState) || isNull(this.props.alertDialogState)) {
      return;
    }

    if (this.props.alertDialogType === 'count') {
      this.handleClearCount(this.props.alertDialogState.device_type, this.props.alertDialogState.device_id);
    } else if(this.props.alertDialogType === 'timer') {
      this.handleClearTimer(this.props.alertDialogState.device_type, this.props.alertDialogState.device_id);
    }

    this.handleCloseAlertDialog();
  }

  
  handleCloseAlertDialog() {
    this.props.closeDoorStateAlert({});
  }


  // MQTT IoT Shadow event

  handleGetDocumentEvent(cur_repo, meta_repo) {
    console.log('handleGetDocumentEvent');

    const cur_states = {};
    const new_states = this.buildNewStatuses(cur_states, cur_repo, meta_repo, this.props.deviceMaster);
    this.props.updateDeviceShadowStates(new_states);
 }

  handleUpdateDocumentEvent(cur_repo, meta_repo) {
    console.log('handleUpdateDocumentEvent');

    const cur_states = this.props.states;
    const new_states = this.buildNewStatuses(cur_states, cur_repo, meta_repo, this.props.deviceMaster);

    this.props.updateDeviceShadowStates(new_states);
  }





  handleCloseEvent() {
    console.log('handleCloseEvent');

    this.props.unsubscribe();
  }


  refresh() {
    this.setState({});  // provisional
  }


  loadPlaceList(deviceType) {
    // Demo Data
    return place_list[deviceType];
  }

  loadDeviceMaster(thingName) {
    // Demo Data
    // ToDo: change to API
    return sensor_device_master[thingName];
  }

  locadThingList() {
    return thing_list;
  }


  buildNewStatuses(cur_states, cur_repo, meta_repo, device_master) {

    Object.keys(cur_repo).forEach((device_type, index) => {
      if (!device_type.endsWith('_event')) {
      Object.keys(cur_repo[device_type]).forEach((device_id, sindex) => {
        // New Device Type
        if ( !(device_type in cur_states) )
          cur_states[device_type] = {};

        // New Device Info
        cur_states[device_type][device_id]={
          state: cur_repo[device_type][device_id],
          timestamp: meta_repo[device_type][device_id]['timestamp'],
          count: 0,
          totalCount: 0,
        };

        if (device_master && device_type in device_master && device_id in device_master[device_type]) {
          cur_states[device_type][device_id]['label'] = device_master[device_type][device_id]['label'];
          cur_states[device_type][device_id]['displayOrder'] = device_master[device_type][device_id]['display_order'];
          cur_states[device_type][device_id]['placeId'] = device_master[device_type][device_id]['place_id'];
        //} else {
        //  console.log('device_master is not match with ' + device_type + ':' + device_id);
        }
        
        if ( (device_type + '_event' in cur_repo) && (device_id in cur_repo[device_type + '_event']) ) { 

          if ('count' in cur_repo[device_type + '_event'][device_id]) {
            cur_states[device_type][device_id]['count'] = cur_repo[device_type + '_event'][device_id]['count'];
          }
          if ('totalCount' in cur_repo[device_type + '_event'][device_id]) {
            cur_states[device_type][device_id]['totalCount'] = cur_repo[device_type + '_event'][device_id]['totalCount'];
          }
          if ('lastCloseTime' in cur_repo[device_type + '_event'][device_id]) {
            cur_states[device_type][device_id]['lastCloseTime'] = cur_repo[device_type + '_event'][device_id]['lastCloseTime'];
            if ('lastTimerClearTime' in cur_repo[device_type + '_event'][device_id]) {
              if (cur_repo[device_type + '_event'][device_id]['lastTimerClearTime'] > cur_repo[device_type + '_event'][device_id]['lastCloseTime']) {
                cur_states[device_type][device_id]['lastTimerClearTime'] = cur_repo[device_type + '_event'][device_id]['lastTimerClearTime'];
              }
            }
          }
          if ('lastOpenTime' in cur_repo[device_type + '_event'][device_id]) {
            cur_states[device_type][device_id]['lastOpenTime'] = cur_repo[device_type + '_event'][device_id]['lastOpenTime'];
          }
        }
      });
      }
    });
    return cur_states;
  }



  getStateList(states, deviceType, placeId) {
        const list = [];
        let inuse = 0;
        let total = 0;
        let unknown = 0;
        //if (!deviceType) deviceType='door';

        if (!(deviceType in states)) {
          //console.log( deviceType  + ' is not in states.');
          return {list:list, inuse:inuse, total:total, unknown:unknown};
        }

        const device_state = states[deviceType];
        Object.keys(device_state).forEach((device_id, sindex) => {
          if (placeId === device_state[device_id]['placeId'] ||
            (placeId==="000" && isUndefined(device_state[device_id]['placeId']))){
            //console.log(device_state[device_id]);
            list.push({
                device_type: deviceType,
                device_id: device_id,
                label: device_state[device_id]['label'],
                state: device_state[device_id]['state'],
                timestamp: device_state[device_id]['timestamp'],
                count: device_state[device_id]['count'],
                lastCloseTime: device_state[device_id]['lastCloseTime'],
                lastTimerClearTime: device_state[device_id]['lastTimerClearTime'],
                lastOpenTime: device_state[device_id]['lastOpenTime'],
                totalCount: device_state[device_id]['totalCount'],
            });

            if (Math.floor(Date.now() / 1000) - device_state[device_id]['timestamp'] > 3600) {
              unknown += 1;
            } else if (device_state[device_id]['state'] === 'close') {
                inuse += 1;
            }
          }
        });

        // TODO: sort by display_order


        total = list.length;
        //console.log( 'Total is ' + total);
        return {list:list, inuse:inuse, total:total, unknown:unknown};
  }

}

