import {
//  CHANGE_MENU_TAB,

  SUBSCRIBE,
  UNSUBSCRIBE,
  CHANGE_THING,
  SHOW_DOOR_STATE_ALERT,
  CLOSE_DOOR_STATE_ALERT,
  SHOW_DOOR_STATE_DETAIL,
  CLOSE_DOOR_STATE_DETAIL,
  UPDATE_DEVICE_SHADOW_STATES,
  UPDATE_PLACE_LIST,
  UPDATE_THING_LIST,
  CHANGE_DOOR_STATE_ITEM_TAB,
} from '../constraints/actionTypes';

export const updatePlaceList = (placeList) => {
  return {
    type: UPDATE_PLACE_LIST,
    placeList: placeList,
  };
};

export const updateThingList = (things) => {
  return {
    type: UPDATE_THING_LIST,
    things: things,
  };
};

// export const changeMenuTab = (menuTab) => {
//   return {
//     type: CHANGE_MENU_TAB,
//     menuTab: menuTab,
//   };
// };

export const changeThing = (thingName, deviceMaster) => {
  return {
    type: CHANGE_THING,
    thing: thingName,
    deviceMaster: deviceMaster,
  };
};

export const unsubscribe = () => {
  return {
    type: UNSUBSCRIBE,
    states: {},
    subscribing: false,
  };
};

export const subscribe = () => {
  return {
    type: SUBSCRIBE,
    states: {},
    subscribing: true,
  };
};

// export const receivedDeviceShadowDocument = (amount) => (
//     {
//       type: 'RECEIVED_DEVICE_SHADOW_DOCUMENT',
//       payload: {
//         amount
//       }
//     }
// );

// export const receivedDeviceShadowStates = (amount) => (
//   {
//     type: 'RECEIVED_DEVICE_SHADOW_STATES',
//     payload: {
//       amount
//     }
//   }
// );

export const updateDeviceShadowStates = (states) => {
   return {
     type: UPDATE_DEVICE_SHADOW_STATES,
     states: states,
   };
};

export const showDoorStateAlert = (doorStateAlert) => {
  return {
    type: SHOW_DOOR_STATE_ALERT,
    dialogType: doorStateAlert.dialogType,
    title: doorStateAlert.title,
    message: doorStateAlert.message,
    state: doorStateAlert.state,
  };
};

export const closeDoorStateAlert = () => {
  return {
    type: CLOSE_DOOR_STATE_ALERT,
  };
};

export const showDoorStateDetail = (doorStateDetail) => {
  return {
    type: SHOW_DOOR_STATE_DETAIL,
    text: doorStateDetail.text,
    report: doorStateDetail.report,
    tab: doorStateDetail.tab,
  };
};

export const closeDoorStateDetail = () => {
  return {
    type: CLOSE_DOOR_STATE_DETAIL,
  };
};

export const changeDoorStateItemTab = (tab) => {
  return {
    type: CHANGE_DOOR_STATE_ITEM_TAB,
    tab: tab,
  };
};
