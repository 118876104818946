import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import root from './reducers/root';

import './index.css';
//import App from './App';
import App from './containers/AppContainer';
//import App from './components/App';
import * as serviceWorker from './serviceWorker';

const store = createStore(root);

console.log('store is created. ');

//ReactDOM.render(<App />, document.getElementById('root'));
render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
