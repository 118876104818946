import { PubSub } from 'aws-amplify';

import { isUndefined, isNull } from 'util';


export class IotWebPubSubClient {

    constructor({ handleGetDocumentEvent, handleUpdateDocumentEvent, handleCloseEvent}) {
      this.handleGetDocumentEvent = handleGetDocumentEvent;
      this.handleUpdateDocumentEvent = handleUpdateDocumentEvent;
      this.handleCloseEvent = handleCloseEvent;
  
      this.update_sub = null;
      this.get_sub = null;
      this.thing_name = null;
      this.subscribing = false;
  
      this.handleReceive = this.handleReceive.bind(this);
      this.handleReceiveGet = this.handleReceiveGet.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleError = this.handleError.bind(this);
      this.publishGet = this.publishGet.bind(this);
    }
  
    startSubscribing(thing_name) {
      if (this.subscribing) {
        this.stopSubscribing();
      }
      this.thing_name = thing_name;
  
      const sub_topic = '$aws/things/' + this.thing_name + '/shadow/get/accepted'
      const update_sub = PubSub.subscribe(sub_topic).subscribe({
        next: this.handleReceiveGet,
        error: error => this.handleError,
        close: () => this.handleClose,
      })
  
      const topic = '$aws/things/' + this.thing_name + '/shadow/update/documents'
      const get_sub = PubSub.subscribe(topic).subscribe({
        next: this.handleReceive,
        error: error => this.handleError,
        close: () => this.handleClose,
      })
  
      this.update_sub = update_sub;
      this.get_sub = get_sub;
      this.subscribing = true;
  
      console.log("startSubscribing");
  
      setTimeout(() => {
        this.publishGet();
      }, 700);
    }
  
    stopSubscribing() {
      if (this.update_sub)
        this.update_sub.unsubscribe();
      this.update_sub = null;
      
      if (this.get_sub)
        this.get_sub.unsubscribe();
      this.get_sub = null;
  
      this.subscribing = false;
  
      console.log("stopSubscribing");
    }
  
    publishGet() {
      if (this.subscribing) {
        const get_topic = '$aws/things/' + this.thing_name + '/shadow/get';
        PubSub.publish(get_topic, '');
  
        console.log("publishGet");
      }
    }
  
    // provisional
    publishClearCount(device_type, device_id) {
      if (this.subscribing) {
        const update_topic = '$aws/things/' + this.thing_name + '/shadow/update';
        const update_value = {"state":{"reported":{}}};
        update_value['state']['reported'][device_type + '_event']={};
        update_value['state']['reported'][device_type + '_event'][device_id] = {
          "count": 0
        };
  
        PubSub.publish(update_topic, update_value);
  
        console.log(update_value);
      }
    }

    // provisional
    publishClearTimer(device_type, device_id) {
      if (this.subscribing) {
        const dt = new Date();


        const update_topic = '$aws/things/' + this.thing_name + '/shadow/update';
        const update_value = {"state":{"reported":{}}};
        update_value['state']['reported'][device_type + '_event']={};
        update_value['state']['reported'][device_type + '_event'][device_id] = {
          "lastTimerClearTime": Math.floor(dt.getTime() / 1000)
        };
  
        PubSub.publish(update_topic, update_value);
  
        console.log(update_value);
      }
    }
    
  
    handleReceive(msg){
      console.log(msg);
  
      if (this.subscribing) {
        const states = msg.value;
        const cur_repo = states['current']['state']['reported'];
        const meta_repo = states['current']['metadata']['reported'];
    
        if (isUndefined(cur_repo) || isUndefined(meta_repo)) {
          return;
        }
    
        // TODO: check thing_name
    
    
        this.handleUpdateDocumentEvent(cur_repo, meta_repo);
      }
    }
  
    handleReceiveGet(msg){
      console.log(msg);
  
      if (this.subscribing) {
        const states = msg.value;
        const cur_repo = states['state']['reported'];
        const meta_repo = states['metadata']['reported'];
        if (isUndefined(cur_repo) || isUndefined(meta_repo)) {
          return;
        }
    
        // TODO: check thing_name
    
        this.handleGetDocumentEvent(cur_repo, meta_repo);
      }
    }
  
    handleClose() {
      console.log('Closed');
      this.handleCloseEvent();
    }
  
    handleError(error) {
      console.log(error);
  
      // ToDo: Clear
  
    }
  
  }
  