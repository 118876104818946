import {
  CHANGE_MENU_TAB,
} from '../constraints/actionTypes';

export const changeMenuTab = (menuTab) => {
  return {
    type: CHANGE_MENU_TAB,
    menuTab: menuTab,
  };
};
