import {
    COUNT_CHANGE_TARGET_DATE,
    COUNT_REQUEST_REPORT_DATA,
    COUNT_RECEIVE_REPORT_DATA,
    COUNT_ERROR_REPORT_DATA,

    COUNT_CHANGE_THING,
    COUNT_UPDATE_PLACE_LIST,
    COUNT_UPDATE_THING_LIST,

  } from '../constraints/actionTypes';

const initialState = {
    targetDate: new Date(),
    reportData: null,
    isLoading: false,
    errorMessage: null,

    thing: '',
    deviceMaster: {},
    placeList: [],
    things: [],
};

const countAnalyze = (state = initialState, action) => {
    console.log('countAnalyze reducer action:' + JSON.stringify(action) + ' state:' + JSON.stringify(state));
    switch (action.type) {

        case COUNT_CHANGE_TARGET_DATE:
        return Object.assign({},
          state,
          {
            targetDate: action.targetDate,
          }
        );
        case COUNT_REQUEST_REPORT_DATA:
        return Object.assign({},
          state,
          {
            reportData: null,
            isLoading: true,
            errorMessage: null,
          }
        );

        case COUNT_RECEIVE_REPORT_DATA:
        return Object.assign({},
          state,
          {
            reportData: action.reportData,
            isLoading: false,
            errorMessage: null,
          }
        );

        case COUNT_ERROR_REPORT_DATA:
        return Object.assign({},
          state,
          {
            reportData: null,
            isLoading: false,
            errorMessage: action.errorMessage,
          }
        );

        case COUNT_CHANGE_THING:
        return Object.assign({},
          state,
          {
            thing: action.thing,
            deviceMaster: action.deviceMaster,
          }
        );
        case COUNT_UPDATE_PLACE_LIST:
        return Object.assign({},
          state,
          {
            placeList: action.placeList
          }
        );

      case COUNT_UPDATE_THING_LIST:
        return Object.assign({},
          state,
          {
            things: action.things
          }
        );
        default:
        console.log('countAnalyze reducer is initialized.');
        return state;
  }
};

export default countAnalyze;